<template>
<v-card color="#F0FFFF" elevation="0" outlined min-width="250" max-width="250" height="280">
  <v-container fluid>
    <div>
      <div class="d-flex px-10 align-center">
        <v-icon @click="prevMonth">mdi-arrow-left-bold</v-icon>
        <v-spacer></v-spacer>
        <div v-if="!!pickedWeek" class="text-subtitle-2">{{ pickedWeek.year }}年{{ pickedWeek.month + 1 }}月</div>
        <v-spacer></v-spacer>
        <v-icon @click="nextMonth">mdi-arrow-right-bold</v-icon>
      </div>
      <div class="pa-4">
        <v-btn small @click="selectWeek(week)" :color="getWeekColor(week)" elevation="0" class="my-1" block v-for="(week, i) in weeks" :key="i">
          <div>{{ week.text }}</div>
          <div class="ml-3">{{ week.start | monthDay }}~{{ week.end | monthDay}}</div>
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="align-center d-flex">
        <v-avatar size="10" color="#80CBC4"></v-avatar>
        <div class="text-caption mx-2">已交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#FFCDD2"></v-avatar>
        <div class="text-caption mx-2">未交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#9575CD"></v-avatar>
        <div class="text-caption mx-2">补交</div>
      </div>
    </div>
  </v-container>
</v-card>
</template>

<script>
import moment from 'moment';
export default {
  name: 'WeekCalendar',
  props: ['current', 'actives', 'expireds'],
  data() {
    return {
      weeks: [],
      thisMonday: null,
      pickedWeek: null,
    }
  },
  methods: {
    setWeeks() {
      this.weeks = [];
      const mDays = new Date(this.pickedWeek.year, this.pickedWeek.month + 1, 0).getDate(); // counts of days of month
      const startMWeekDay = new Date(this.pickedWeek.year, this.pickedWeek.month, 1).getDay() == 0 ? 7 : new Date(this.pickedWeek.year, this.pickedWeek.month, 1).getDay(); // get week day, Monday = 7
      let firstDay = null;
      if (startMWeekDay !== 1) {
        firstDay = new Date(this.pickedWeek.year, this.pickedWeek.month, 9 - startMWeekDay);
      } else {
        firstDay = new Date(this.pickedWeek.year, this.pickedWeek.month, 1);
      }
      const weeksCount = Math.ceil((mDays - firstDay.getDate()) / 7);
      for (let i = 0; i < weeksCount; i++) {
        const start = new Date(firstDay);
        start.setDate(start.getDate() + i * 7);
        const end = new Date(start);
        end.setDate(end.getDate() + 6);
        this.weeks.push({
          number: i + 1,
          text: `第${i + 1}周`,
          start: start,
          end: end
        })
      }
      const today = new Date();
      const day = today.getDay();
      const diff = today.getDate() - day + (day == 0 ? -6 : 1);
      this.thisMonday = new Date(today.setDate(diff));
      this.weeks.map(week => {
        if (week.start.getDate() === this.thisMonday.getDate()) {
          // this.pickedWeek.week = week.number;
          this.$emit('pick', `${this.pickedWeek.year}-${this.pickedWeek.month +1}/${week.number}`);
          this.$emit('range', week);
        }
      })
    },
    prevMonth() {
      if (this.pickedWeek.month > 0) {
        this.pickedWeek.month -= 1;
      } else {
        this.pickedWeek.year -= 1;
        this.pickedWeek.month = 11;
      }
      this.setWeeks();
    },
    nextMonth() {
      if (this.pickedWeek.month < 11) {
        this.pickedWeek.month += 1;
      } else {
        this.pickedWeek.year += 1;
        this.pickedWeek.month = 0;
      }
      this.setWeeks();
    },
    getWeekColor(week) {
      const monday = new Date(week.start).getTime();
      const thisMonday = new Date(this.thisMonday).getTime();
      if (monday > thisMonday) {
        return '';
      } else if (!!this.actives) {
        const btnWeek = `${this.pickedWeek.year}-${this.pickedWeek.month+1}/${week.number}`;
        if (this.actives.includes(btnWeek)) {
          return '#66CDAA';
        } else if (this.expireds.includes(btnWeek)) {
          return '#7B68EE';
        }
      }
      return '#F08080';
    },
    selectWeek(week) {
      this.pickedWeek.week = week.number
      this.$emit('pick', `${this.pickedWeek.year}-${this.pickedWeek.month +1}/${this.pickedWeek.week}`);
      this.$emit('range', week);
    }
  },
  mounted() {
    this.pickedWeek = Object.assign({}, this.current);
    this.setWeeks();
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day == 0 ? -6 : 1);
    this.thisMonday = new Date(today.setDate(diff));
  },
  filters: {
    monthDay: function (date) {
      let day = new Date(date);
      day = moment(day).format('MM/DD');
      return day
    },
  }
}
</script>
